@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@400;700&display=swap");

:root {
  --clr-primary: hsl(25, 97%, 53%);
  --clr-white: hsl(0, 0%, 100%);
  --clr-light-grey: hsl(217, 12%, 63%);
  --clr-medium-grey: hsl(216, 12%, 54%);
  --clr-dark-blue: hsl(213, 19%, 18%);
  --clr-darker-blue: hsl(216, 12%, 8%);
  --clr-gark-grey: #38383d;
}

body {
  margin: 0;
  font-family: "Overpass", sans-serif;
  background-color: var(--clr-darker-blue);
}

.btn {
  font-family: "Overpass", sans-serif;
}

.main-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.main-card {
  width: 25%;
  /*height: 40%;*/
  background-color: var(--clr-dark-blue);
  padding: 2rem;
  border-radius: 1.2rem;
  color: var(--clr-white);
}

@media screen and (max-width: 375px) {
  .main-card {
    width: 70%;
  }
}
