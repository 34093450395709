.thankYou-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.thankYou-template {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.thankYou-image-div {
    margin-bottom: 1.2rem;
}

.thankYou-template-header {
    margin-bottom: 0.2rem;
}

.thankYou-rating-div {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.thankYou-rating-span {
    padding: 0.4rem 1rem;
    border-radius: 3rem;
    background-color: var(--clr-gark-grey);
    font-size: 15px;
    color: var(--clr-primary);
}