.starBtn {
    border-radius: 100%;
    padding: 0.5rem;
    width: 3rem;
    aspect-ratio: 1/1;
    border: none;
    background-color: var(--clr-gark-grey);
    color: var(--clr-primary);
}

.template-header {
    font-weight: 700;
    font-size: 25px;
    color: var(--clr-white);
}

.template-paragraph {
    font-weight: 400;
    font-size: 15px;
    color: var(--clr-medium-grey);
    line-height: 1.5rem;
}

.ratingCard-div {
    display: flex;
    justify-content: space-around;
    margin: 1.3rem auto;
}


.submitBtn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    border-radius: 1.5rem;
    padding: 0.7rem;
    background-color: var(--clr-primary);
    transition: all 100ms ease;
    cursor: pointer;
}
.submitBtn {
    border: none;
    color: var(--clr-white);
    background: transparent;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;
}

.submitBtn-div:hover {
    background-color: var(--clr-white);
}

.submitBtn-div:hover .submitBtn {
    color: #ea5a5a;
}

@media screen and (max-width: 375px) {
    .ratingCard-div {
        gap: 1rem;
    }
}